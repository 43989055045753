.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 70px);
  font-family: "Oswald";
  z-index: 99;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  max-width: 780px;
  height: auto;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 5px 5px 50px rgba(20, 110, 55, 1);
  color: #000;
}

.btn {
  margin: 10px;
  width: 90%;
  margin-bottom: 1rem;
  font-size: 25px;
  color: white;
  background: linear-gradient(
    90deg,
    rgb(28, 22, 255),
    0%,
    rgb(0, 102, 255) 100%
  );
}

.btn:hover {
  cursor: pointer;
  box-shadow: 0 0.4rem 1.4rem 0 rgba(8, 114, 244, 0.6);
  transition: transform 150ms;
  transform: scale(1.03);
}

.finalScore {
  font-size: 4rem;
  color: white;
}

/* Regras de mídia queries para ajustar o layout em diferentes tamanhos de tela */

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
  }
  .finalScore {
    font-size: 35px;
  }

  .form h2 {
    font-size: 20px;
    color: white;
    text-align: center;
  }
  .form input {
    width: 90%;
    padding: 10px;
    font-size: 25px;
    margin-bottom: 10px;
    width: 100%;
    outline: none;
  }

  .btn,
  .endFormContainer input {
    width: 100%;
  }
}
