.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gameForm {
  width: 780px;
  max-width: 95%;
  max-height: 95%;
  padding: 5px 30px;
  border-radius: 20px;
  box-shadow: 5px 5px 50px rgba(20, 110, 55, 1);
  background: #fff;
  color: #000;
  overflow: auto;
}
.gameForm::-webkit-scrollbar {
  width: 10px;
}

.gameForm::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Cor do fundo da barra de rolagem */
  border-radius: 10px;
}

.gameForm::-webkit-scrollbar-thumb {
  background-color: rgba(20, 110, 55, 1);
  border-radius: 5px; /* Raio do "ponteiro" da barra de rolagem */
}

.gameForm::-webkit-scrollbar-thumb:hover {
  background-color: rgb(
    33,
    176,
    87
  ); /* Cor do "ponteiro" da barra de rolagem ao passar o mouse */
}

.hud {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.progress {
  width: 50%;
}
.progress p,
.points p {
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}

.progressBar {
  height: 35px !important;
  border-radius: 25px !important;
}

.points {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 50%;
  margin: 0;
}

.question {
  width: 100%;
  max-height: 300px;
  font-size: 35px;
  overflow-y: auto;
}

.box {
  color: #fff;
  min-height: 55px;
  background: rgb(100, 200, 50);
  background: linear-gradient(
    90deg,
    rgba(100, 200, 50, 1) 0%,
    rgba(20, 110, 55, 1) 100%
  );
  text-transform: uppercase;
  margin: 20px;
  cursor: pointer;
  border-radius: 15px;
  transition: 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box:hover {
  transform: scale(1.02);
  transition: 300ms;
}

.box span {
  font-size: 25px;
  text-align: center;
}

.footer {
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn {
  width: 125px;
}

.selected {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  border: 2px solid rgba(18, 255, 0, 1);
  transform: scale(1.04);
}

.hit {
  background: rgb(18, 255, 0);
}

.miss {
  background: rgb(255, 0, 31);
  background: linear-gradient(
    16deg,
    rgba(255, 0, 31, 1) 0%,
    rgba(135, 0, 0, 1) 100%
  );
}

/* Media queries para telas menores */

@media only screen and (max-width: 1024px) {
  .gameForm {
    width: 90%;
    padding: 20px;
  }
}

@media only screen and (max-width: 620px) {
  .gameForm {
    position: fixed;
    top: 75px;
    max-height: 85%;
    margin: 0;
  }

  .progress {
    width: 100%;
  }
  .question {
    font-size: 25px;
  }
  .box {
    margin: 10px;
    padding: 1px;
  }
  .box p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .gameForm {
    padding: 10px;
  }

  .progress {
    margin-bottom: 10px;
  }
}
