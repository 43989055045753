.rank {
  width: 100%;
  max-height: 90%;
  overflow-y: auto;
}

.header tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  color: #007f87;
  font-size: 30px;
  margin-bottom: 15px;
  text-align: center;
}

.header {
  max-width: 100%;
  top: 0;
  border-radius: 10px;
}
.header th {
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.header th:first-child {
  width: 50%;
}

.header th:last-child {
  text-align: end;
  width: 50%;
  text-align: center;
}
.scores {
  height: 100%;
  width: 100%;
}
.scores table {
  width: 100%;
}
.body {
  width: 100%;
}
.body td:first-child {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.body td:last-child {
  text-align: center;
  width: 50%;
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid rgb(255, 255, 255);
  color: white;
  font-size: 30px;
  white-space: nowrap;
  height: 4rem;
}

.line img {
  width: 75px;
  height: 60px;
  margin-top: -15px; /* Altere de top para margin-top */
}
.line td {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 8px;
}
.podium_1 {
  border: 2px solid goldenrod;
  margin-bottom: 25px;
}
.podium_2 {
  border: 2px solid silver;
  margin-bottom: 25px;
}
.podium_3 {
  border: 2px solid #cd7f32;
  margin-bottom: 25px;
}
/* Estilos para telas menores e dispositivos móveis */

@media only screen and (max-width: 620px) {
  .rank {
    padding: 5px;
    max-width: 98%;
  }
  .header tr,
  .body tr {
    font-size: 20px;
  }

  .podium_1,
  .podium_2,
  .podium_3 {
    margin-bottom: 15px;
  }
}
