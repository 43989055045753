.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}

.form {
  /* background-color: lime; */

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.form h1 {
  font-size: 85px;
  color: #fff;
  margin-bottom: 30px;
  text-align: center;
}

.form input {
  width: 500px;
  padding: 15px;
  font-size: 25px;
  border: none;
  box-shadow: 0 1px 15px 0 rgba(86, 185, 235, 1);
  margin-bottom: 15px;
  outline: none;
}

.btn {
  font-size: 40px;
  padding: 20px 0;
  width: 500px;
  text-align: center;
  margin: 15px;
  text-decoration: none;
  color: rgb(28, 26, 26);
  border: none;
  background: linear-gradient(
    90deg,
    rgb(28, 22, 255),
    0%,
    rgb(0, 102, 255) 100%
  );
  border-radius: 4px;
  transition: 0.1s;
}

.btn:hover {
  cursor: pointer;
  box-shadow: 0 1px 25px 0 rgba(8, 114, 244, 0.6);
  transition: 0.1s;
  transform: scale(1.03);
}

.highScore {
  font-size: 25px;
  background: linear-gradient(
    90deg,
    rgb(255, 247, 9) 0%,
    rgb(200, 221, 6) 100%
  );
}

.highScore:hover {
  box-shadow: 0 1px 25px 0 rgba(255, 255, 0, 0.5);
}

@media (max-height: 800px) {
  .content h1 {
    font-size: 70px;
  }
  .content input {
    padding: 5px;
  }

  .btn {
    font-size: 32px;
    width: 80%;
    padding: 10px;
  }

  .highScore {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .content {
    margin: 0;
    align-items: start;
  }
  .form {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0;
    justify-content: flex-start;
    padding-top: 20%;
  }
  .content h1 {
    font-size: 48px;
  }
  .content input {
    width: 80%;
    padding: 5px;
  }

  .btn {
    font-size: 32px;
    width: 80%;
    padding: 10px;
  }

  .highScore {
    font-size: 20px;
  }
}
