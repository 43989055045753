@font-face {
  font-family: "Comic Sans MS";
  src: url("/src/assets/fonts/COMIC.TTF");
  /* Adicione outras propriedades opcionais, como font-weight e font-style, se necessário */
}

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

