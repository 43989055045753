.nav {
  background: rgb(100, 200, 50);
  width: 100vw;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
}
.nav img {
  height: 44px;
  width: 170px;
  margin-left: 10px;
}
