.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
  width: 100vw;
  z-index: 99;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 5px 5px 50px rgba(20, 110, 55, 1);
  max-width: 90%;
  min-width: 900px;
  max-height: 90%;
}

.btn {
  width: 90%;
  margin-bottom: 1rem;
  font-size: 25px !important;
  color: white;
  border: none !important;
  background: linear-gradient(
    90deg,
    rgb(28, 22, 255),
    0%,
    rgb(0, 102, 255) 100%
  );
}

.btn:hover {
  cursor: pointer;
  box-shadow: 0 0.4rem 1.4rem 0 rgba(8, 114, 244, 0.6);
  transition: transform 150ms;
  transform: scale(1.03);
}

.foster {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  width: 70%;
}

/* Media query for screens smaller than 768px (mobile devices) */
@media (max-width: 768px) {
  .container {
    width: 100%;
    margin: 0;
  }
  .content {
    width: 100%;
    margin: 0px;
    max-width: 100%;
    padding: 10px;
    min-width: 50%;
  }

  .btn {
    width: 350px;
  }

  .btn:hover {
    cursor: pointer;
    box-shadow: 0 0.4rem 1.4rem 0 rgba(8, 114, 244, 0.6);
    transition: transform 150ms;
    transform: scale(1.03);
  }
}
