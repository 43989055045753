.main {
  width: 100vw;
  height: calc(100vh - 70px);
  position: fixed;
  left: 0;
  background: rgb(100, 200, 50);
  background: linear-gradient(
    90deg,
    rgba(100, 200, 50, 1) 0%,
    rgba(20, 110, 55, 1) 100%
  );
  top: 70px;
  font-family: "Comic Sans MS";
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 100px;
  color: white;
}
.content h1 {
  font-size: 45px;
}
.content h2 {
  font-size: 40px;
}

.notFound {
  max-width: 90%;
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
    height: calc(100vh - 80px);
  }

  .container h1 {
    font-size: 25px;
    text-align: center;
  }
  .container h2 {
    font-size: 20px;
  }

  .notFound {
    max-width: 100%;
    max-height: 45%;
  }
}
